<template>
  <div>
    <el-row :gutter="12" v-if="type == 2">
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-document"></i> 文章</div>
          <div class="value">{{count.articleCount}}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-view"></i> 查看</div>
          <div class="value">{{count.viewCount}}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-thumb"></i> 点赞</div>
          <div class="value">{{count.niceCount}}</div>
        </el-card>
      </el-col>
      <el-col :span="6" v-for="(item,index) in countMini" :key="index">
        <el-card shadow="hover">
          <div class="miniLabel">{{item.name}}</div>
          <div class="miniValue">
            <i class="el-icon-document"></i> {{item.article}} 
            <el-divider direction="vertical"></el-divider>
            <i class="el-icon-view"></i> {{item.view}} 
            <el-divider direction="vertical"></el-divider>
            <i class="el-icon-thumb"></i> {{item.nice}} 
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="12" style="margin-top:20px" v-if="type == 2">
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-mobile-phone"></i> 微信小程序</div>
          <div class="value">{{count.miniCount}}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-user"></i> 医生</div>
          <div class="value">{{count.doctorCount}}</div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="label"><i class="el-icon-user"></i> 管理员</div>
          <div class="value">{{count.adminCount}}</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'home',
    data() {
      return {
				type:'',
        count:{},
        countMini:[{
          name:'点点儿童之家',
          article:242344,
          view:123123,
          nice:234
        },{
          name:'降糖好帮手',
          article:242344,
          view:123123,
          nice:234
        },{
          name:'胆道闭锁医患通',
          article:242344,
          view:123123,
          nice:234
        },{
          name:'儿童肠内营养计算器',
          article:242344,
          view:123123,
          nice:234
        },{
          name:'儿童摇篮之家',
          article:242344,
          view:123123,
          nice:234
        },{
          name:'儿医营养保障',
          article:242344,
          view:123123,
          nice:234
        }]
      }
    },

    created() {
			this.type = window.localStorage.type
      this.getCount()
      this.getCountMini()
    },

    methods: {
      getCount() {
        this.$http.post('/index/count').then(res => {
          if (res.data.code == 1) {
            this.count = res.data.data
          }
        })
      },

      getCountMini() {
        this.$http.post('/index/countMini', {}).then(res => {
          if (res.data.code == 1) {
            this.countMini = res.data.data
          }
        })
      }
    }
  }
</script>

<style scoped>
  .label {
    font-size: 20px;
    color: #bfbfbf;
  }

  .value {
    font-size: 40px;
    font-weight: 700;
    margin-top: 10px;
    color: #409EFF;
  }

  .miniLabel{
    font-size: 20px;
    font-weight: 700;
  }

  .miniValue{
    font-size: 20px;
    margin-top: 20px;
    color: #bfbfbf;
  }

  .el-card{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 50px;
    border: none!important;;
  }

  .el-col {
    margin-bottom: 10px;
  }
</style>